
export const columns_config = () => {
	return [
		// {
		// 	name: "装箱类型",
		// 	data: 'inWhType',
		// 	// editor: 'select',
		// 	// selectOptions: []
		// 	type: 'dropdown',
		// 	source: []
		// },
		{
			name: "外箱标识",
			nameEn: "Box Mark",
			data: 'ctnMark',
			type: 'text'
		},
		{
			name: "箱数",
			nameEn: "Box Number",
			data: 'ctnCount',
			type: 'numeric',
		},
		{
			name: "托数",
			nameEn: "Pllate Number",
			data: 'inWhPallte',
			type: 'numeric',
		},

		{
			name: "箱子长",
			nameEn: "Box Length",
			data: 'ctnL',
			type: 'numeric',
		},
		{
			name: "箱子宽",
			nameEn: "Box Width",
			data: 'ctnW',
			type: 'numeric',
		},
		{
			name: "箱子高",
			nameEn: "Box Heiht",
			data: 'ctnH',
			type: 'numeric',
		},
		{
			name: "单箱重量",
			nameEn: "Box Weight",
			data: 'ctnWeight',
			type: 'numeric',
		},
		{
			name: "单位",
			nameEn: "Unit",
			data: 'ctnUnit',
			// editor: 'select',
			// selectOptions: [],
			type: 'dropdown',
			source: []
		},
		{
			name: "箱内SKU",
			nameEn: "SKU",
			data: 'goodsSku',
			type: 'text'
		},
		{
			name: "每箱件数",
			nameEn: "CTN/PSC",
			data: 'ctnSkuCount',
			type: 'numeric',
		},

		{
			name: "总件数",
			nameEn: "Total PSC",
			data: 'skuTotal',
			type: 'numeric',
		},
		// {
		// 	name: "备注",
		// 	data: 'remark',
		// 	type: 'text'
		// },
		{
			name: "ID",
			data: 'id',
			type: 'text',
			readOnly: true
		},
	];;
}

export const nestedHeadersTop_config = () => {
	return [];
}